* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #000;
  overflow: hidden;
  font-family: sans-serif;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.toolbar {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 9999;
  display: flex;
}

.toolbar svg {
  height: 25px;
}

.toolbar svg.white path {
  fill: white;
}

.toolbar__item {
  margin-right: 15px;
  opacity: 0.6;
  cursor: pointer;
  transition: all 0.3s;
}

.toolbar__item:last-child {
  margin-right: 0;
}

.toolbar__item:hover {
  opacity: 0.8;
}

.toolbar__item.active {
  opacity: 1;
}

.toolbar__item.active:hover {
  opacity: 0.8;
}

.copyright {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px 0;
  text-align: center;
  opacity: 0.5;
  z-index: 9999;
  transition: opacity 0.3s;
}

.copyright:hover {
  opacity: 1;
}

.copyright a {
  color: blue;
  text-decoration: none;
}

.copyright a:hover {
  text-decoration: underline;
}

.copyright.white {
  color: white;
}

.copyright.white a {
  color: white;
}
